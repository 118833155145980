import React from 'react'
import './Rules.scss'
import HeroBanner from '../../components/HeroBanner'
import Layout from '../../components/Layout'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { graphql } from 'gatsby'

const Rules = ({ data }) => {
  return (
    <Layout>
      <div className="Rules">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="Our Rules"
          caption="We have as much fun as the next person, but safety first."
        />
        <div className="container content">
          <p>
            So with that in mind, here we have a few rules that must be adhered
            to at all times while on our course. Feel free to have a look and if
            things are still unclear, head over the FAQ and try to find your
            answers there.
          </p>

          <ListGroup>
            <ListGroupItem>
              <strong>3 points of contact</strong> - Maintain 3 points of
              contact on the climbing obstacles at all times (that’s 2 feet and
              1 hand or 2 hands and 1 foot)
            </ListGroupItem>
            <ListGroupItem>
              <strong>
                Weights and Lifting Equipment (16 + or with adult supervision)
              </strong>{' '}
              - We offer a wide array of weight lifting equipment for your use -
              however if not used properly it can lead to serious injury (and
              damage to the equipment)! Please ensure anyone under the age of 16
              is supervised by an adult - and feel free to ask a staff member if
              you're trying for the first time!
            </ListGroupItem>
            <ListGroupItem>
              <strong>Salmon Ladder</strong> - A popular obstacle in our gym but
              it’s a little technical. We ask that you can do at least 5 pull
              ups and that you have a staff supervise before the first attempt!
            </ListGroupItem>
            <ListGroupItem>
              <strong>Occupancy Limit</strong> - Maximum of 5 people on our
              spider crawl, dip walk, and slip ramp at one time! Ask a staff
              member on your arrival which obstacles those are.
            </ListGroupItem>
            <ListGroupItem>
              <strong>Slip Ramp</strong> - If you climb up you’ve committed to
              going off the back! There is no sliding down the slip ramp. We
              offer a big mat for jumping down (if jumping down break your fall
              by landing on your feet and butt) or 3 ladders if you want to
              climb down (remembering the 3 points of contact!)
            </ListGroupItem>
            <ListGroupItem>
              <strong>Feet below your shoulders</strong> - At the OCRA we are
              ocr racers and not acrobats! For your safety please do not flip or
              hang upside on the obstacles. We keep our feet below our shoulders
              at all times.
            </ListGroupItem>
            <ListGroupItem>
              <strong>Hanging Obstacles</strong> - Our green rig, orange rig and
              multi-rig are known as hanging obstacles because all of the
              obstacles hang from the rigs. Do not climb on top of the rigs,
              there’s nothing to see up there.
            </ListGroupItem>
            <ListGroupItem>
              <strong>Bathroom</strong> - We keep it clean for you, please keep
              it clean for us!
            </ListGroupItem>
            <ListGroupItem>
              <strong>Food</strong> - Bringing your own food is A-Okay, but make
              sure it stays off the course. We have tables and couches for you
              to lounge and enjoy.{' '}
            </ListGroupItem>
            <ListGroupItem>
              <strong>Big Blue Floor Mat</strong> - The big blue floor mat is a
              'no run' zone. It's located under our tarzan swing where people
              are swinging in the air and there is an increased risk of getting
              kicked in the head. Do not cross.
            </ListGroupItem>
            <ListGroupItem>
              <strong>Climbing Rope</strong> - As named the ropes are for
              climbing and not swinging around. Please only climb!
            </ListGroupItem>
            <ListGroupItem>
              <strong>The Most Important Rule of All</strong> - Have Fun!
            </ListGroupItem>
          </ListGroup>
        </div>
      </div>
    </Layout>
  )
}

export default Rules

export const query = graphql`
  {
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
